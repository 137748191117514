.me-block.me-StickyPanel{
  align-items: center;
}
.social{
  display: flex;
  .twitter, .linkedin, .google{padding-top: 7px;}
  .facebook, .twitter, .linkedin, .google{margin: 0 5px;}
}
//.item.slick-slide > .me-block.me-MultiCol {
//  position: absolute;
//  bottom: 0;
//  z-index:99;
//}

@media screen and (max-width: 640px) {
  .copyright{text-align: center;}
}

@media screen and (max-width: 414px){
  .me-block.me-Slider{
    .me-block.me-Image {
      overflow: hidden;
      img{
        min-height: 250px;
        width: auto;
        max-width: 200%;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
