body { background-color: $COL1; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 30px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL13;
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 30px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL14;
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 30px;

}
h4 {
color: $COL15;
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL16;
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

}
h6 {
color: $COL17;
font-family: 'Josefin Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
.button {
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 24px;

}
}
.me-Quote .quote-author {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL11;
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL26;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
summary {
font-family: 'Josefin Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Josefin Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 22px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Josefin Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
@media #{$large-up} {
font-size: 22px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Josefin Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Gilda Display';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: $COL8}
/* Shade 2:34 */
.MES34 {
background-color: $COL27;
 }
/* Dark:26 */
.MES26 {
background-color: $COL4;
color: $COL18;
padding: 15px 40px;

 }
/* Check out:27 */
.MES27 {
color: $COL20;
&:hover { color: $COL20;}
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* Top Bar:28 */
.MES28 {
background-color: $COL25;
color: $COL2;
font-size: 18px;
@media #{$large-up} {
font-size: 23.4px;
};
 }
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: $COL2;
 }
a.MEC28 { color: $COL2;
&:hover { color: $COL3; }
 }
cite.MEC28{
color: $COL2;
font-size: 18px;
@media #{$large-up} {
font-size: 23.4px;
};
}
/* home banner:29 */
.MES29 {
background-color: $COL21;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://secondchildhood.com.au/img/8424/31');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL20;
min-height:400px;
@media #{$medium-up} {
min-height: 650px;};
@media #{$large-up} {
min-height: 700px;};
 }
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: $COL20;
 }
.MEC29 li {color: $COL20;}
cite.MEC29{
color: $COL20;
}
/* :30 */
.MES30 {
padding: 15px;

& > hr {border-width: 1px;}
& > hr {border-color: $COL1;}
& > hr {border-top-style: dashed;}
& > hr {border-right-style: dotted;}
& > hr {border-bottom-style: dotted;}
& > hr {border-left-style: dotted;}
 }
/* Form panel:31 */
.MES31 {
background-color: $COL5;
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: $COL5;
 }
/* Footer Menu:32 */
nav.me-Menu.MES32 {
& .menu-item.MEC32, & .menu-item.MEC32 > div.MEC32{ & > a.MEC32{color: $COL5;
font-size: 16.2px;
}
 &:hover > a.MEC32{color: $COL1;
}
 }
&.horizontal > .menu-item.MEC32 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC32 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC32 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC32 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal:33 */
.MES33 {
background-color: $COL20;
color: $COL24;
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: $COL24;
 }
/* Shade 2:35 */
.MES35 {
background-color: $COL27;
 }
/* Product Tile:24 */
.MES24 {
color: $COL2;
font-size: 18px;
 }
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: $COL6;
 }
h1.MEC24 { @media #{$large-up} { font-size: 32px; }; }
h2.MEC24 { @media #{$large-up} { font-size: 28px; }; }
h3.MEC24 { @media #{$large-up} { font-size: 24px; }; }
h4.MEC24 { @media #{$large-up} { font-size: 20px; }; }
h5.MEC24 { @media #{$large-up} { font-size: 14.4px; }; }
h6.MEC24 { @media #{$large-up} { font-size: 12.8px; }; }
cite.MEC24{
color: $COL2;
font-size: 18px;
}
/* Shade 3:36 */
.MES36 {
background-color: $COL28;
 }
/* Shade 3:37 */
.MES37 {
background-color: $COL28;
 }
/* Add To Cart:38 */
.MES38 {
color: $COL29;
&:hover { color: $COL2;}
font-size: 14.4px;
padding: 5px;

 }
/* View More:39 */
.MES39 {
color: $COL6;
&:hover { color: $COL29;}
font-size: 14.4px;
padding: 5px;

 }
/* Top menu:40 */
nav.me-Menu.MES40 {
& .menu-item.MEC40, & .menu-item.MEC40 > div.MEC40{ & > a.MEC40{color: $COL2;
}
 &:hover > a.MEC40{color: $COL29;
}
 }
&.horizontal > .menu-item.MEC40 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC40 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC40 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL4;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC40 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 30px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC40:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL3;}}
&.vertical .menu-item.MEC40:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL3;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC40{background-color: $COL3;
 &:hover {background-color: $COL4}
}
& .menu-item.MEC40, & .menu-item.MEC40 > div.MEC40{ & > a.MEC40{color: $COL4;
}
 &:hover > a.MEC40{color: $COL20;
}
 }

}
}
 }
/* Footer  menu:41 */
nav.me-Menu.MES41 {
& .menu-item.MEC41, & .menu-item.MEC41 > div.MEC41{ & > a.MEC41{color: $COL2;
}
 &:hover > a.MEC41{color: $COL29;
}
 }
&.horizontal > .menu-item.MEC41 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC41 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC41 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC41 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Cart button:42 */
.MES42 {
background-color: $COL3;
&:hover { background-color: $COL29;}
color: $COL2;
&:hover { color: $COL20;}
 }
/* Cart Preview:43 */
.MES43 {
background-color: $COL3;
padding: 10px;

.MEC43 {background-color: $COL2;
color: $COL20;
padding: 5px 30px;

font-size: 18px;
@media #{$large-up} {
font-size: 13.5px;
};
border-style: solid;
&:hover {background-color: $COL4;
color: $COL20;
}
}
 }
/* Contact Form:25 */
.me-block.me-Form.MES25,  body.MES25 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL4;
&:hover {background-color: $COL4}
border-width: 0;
color: $COL20;
&:hover {color: $COL20}
font-size: 120%;
padding: 10px 15px;

}
@include placeholder($COL2);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
background-color: $COL5;
color: $COL2;
}
::-webkit-input-placeholder { color: $COL2; opacity: 0.5;}
:-moz-placeholder {color: $COL2; opacity: 0.5;} 
::-moz-placeholder {color: $COL2; opacity: 0.5;}
:-ms-input-placeholder {color: $COL2; opacity: 0.5;}

  }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: $COL2;
}
color: $COL20;
& > article { color: $COL20;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL9;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL6;
 }
/* Primary:4 */
.MES4 {
background-color: $COL2;
color: $COL9;
 }
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL9;
 }
cite.MEC4{
color: $COL9;
}
/* Secondary:5 */
.MES5 {
background-color: $COL3;
color: $COL10;
 }
/* Secondary:6 */
.MES6 {
background-color: $COL3;
color: $COL10;
 }
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL10;
 }
cite.MEC6{
color: $COL10;
}
/* Dark:7 */
.MES7 {
background-color: $COL4;
color: $COL18;
 }
/* Dark:8 */
.MES8 {
background-color: $COL4;
color: $COL1;
 }
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL1;
 }
a.MEC8 { color: $COL5;
&:hover { color: $COL3; }
 }
cite.MEC8{
color: $COL1;
}
/* Light:9 */
.MES9 {
background-color: $COL5;
 }
/* Light:10 */
.MES10 {
background-color: $COL5;
 }
/* Shade 1:11 */
.MES11 {
background-color: $COL6;
 }
/* Menu:13 */
.MES13 {
background-color: $COL20;
color: $COL4;
font-size: 18px;
@media #{$medium-up} {
font-size: 19.8px;
};
@media #{$large-up} {
font-size: 18px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
padding: 0;

border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent $COL1 transparent;
 }
a.MEC13 { color: $COL4;
&:hover { color: $COL4; }
 }
cite.MEC13{
color: $COL4;
font-size: 18px;
@media #{$medium-up} {
font-size: 19.8px;
};
@media #{$large-up} {
font-size: 18px;
};
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: $COL5;
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* home block panels:14 */
.MES14 {
background-color: $COL21;
color: $COL20;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL20;
 }
.MEC14 li {color: $COL20;}
cite.MEC14{
color: $COL20;
}
/* Pop out text:15 */
.MES15 {
background-color: $COL20;
color: $COL4;
font-size: 19.8px;
@media #{$large-up} {
font-size: 21.6px;
};
 }
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL4;
 }
.MEC15 li {color: $COL4;}
cite.MEC15{
color: $COL4;
font-size: 19.8px;
@media #{$large-up} {
font-size: 21.6px;
};
}
/* Hollaw:16 */
.MES16 {
&:hover { background-color: $COL22;}
color: $COL20;
&:hover { color: $COL20;}
font-size: 18px;
@media #{$large-up} {
font-size: 19.8px;
};
border-width: 2px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: $COL20;
color: $COL4;
font-size: 18px;
 }
a.MEC18 { color: $COL4;
&:hover { color: $COL4; }
 }
cite.MEC18{
color: $COL4;
font-size: 18px;
}
/* Slider text 2:19 */
.MES19 {
 }
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL20;
 }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 21.6px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 19.2px; }; }
/* Footer Dark:20 */
.MES20 {
background-color: $COL3;
color: $COL2;
font-size: 14.4px;
 }
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL2;
 }
a.MEC20 { color: $COL2;
&:hover { color: $COL20; }
 }
cite.MEC20{
color: $COL2;
font-size: 14.4px;
}
/* News grid:21 */
.MES21 {
color: $COL4;
border-width: 1px;
border-style: solid;
border-color: $COL1 $COL5;
 }
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL2;
 }
cite.MEC21{
color: $COL4;
}
/* cartpreview:44 */
.MES44 {
color: $COL4;
font-size: 18px;
@media #{$large-up} {
font-size: 16.2px;
};
 }
h1.MEC44 { @media #{$large-up} { font-size: 36px; }; }
h2.MEC44 { @media #{$large-up} { font-size: 31.5px; }; }
h3.MEC44 { @media #{$large-up} { font-size: 27px; }; }
h4.MEC44 { @media #{$large-up} { font-size: 22.5px; }; }
h5.MEC44 { @media #{$large-up} { font-size: 16.2px; }; }
h6.MEC44 { @media #{$large-up} { font-size: 14.4px; }; }
cite.MEC44{
color: $COL4;
font-size: 18px;
@media #{$large-up} {
font-size: 16.2px;
};
}
