$COL1: #f2f2f2; //
$COLFLAT1: #f2f2f2; //
$COL2: #1b1b1b; //
$COLFLAT2: #1b1b1b; //
$COL3: #aad9e7; //
$COLFLAT3: #aad9e7; //
$COL4: #070707; //
$COLFLAT4: #070707; //
$COL5: #f2f2f2; //
$COLFLAT5: #f2f2f2; //
$COL6: #848484; //
$COLFLAT6: #848484; //
$COL7: #1b1b1b; //
$COLFLAT7: #1b1b1b; //
$COL8: #4eb0cd; //
$COLFLAT8: #4eb0cd; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #4eb0cd; //
$COLFLAT11: #4eb0cd; //
$COL12: #1b1b1b; //
$COLFLAT12: #1b1b1b; //
$COL13: #1b1b1b; //
$COLFLAT13: #1b1b1b; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #1b1b1b; //
$COLFLAT15: #1b1b1b; //
$COL16: #1b1b1b; //
$COLFLAT16: #1b1b1b; //
$COL17: #1b1b1b; //
$COLFLAT17: #1b1b1b; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: #000000; //Black
$COLFLAT21: #000000; //Black
$COL22: rgba(0,0,0,0.369); //black transparent
$COLFLAT22: #515151; //black transparent
$COL23: rgba(255,255,255,0.302); //white transparent
$COLFLAT23: #a6a6a6; //white transparent
$COL24: #009cde; //Paypal Blue
$COLFLAT24: #009cde; //Paypal Blue
$COL25: rgba(0,0,0,0); //
$COLFLAT25: #808080; //
$COL26: #4eb0cd; //
$COLFLAT26: #4eb0cd; //
$COL27: #aad9e7; //
$COLFLAT27: #aad9e7; //
$COL28: #e8e8e8; //
$COLFLAT28: #e8e8e8; //
$COL29: #4eb0cd; //Buy Now Button
$COLFLAT29: #4eb0cd; //Buy Now Button
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: px;
