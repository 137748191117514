figure{margin:0;}

.me-block{width: 100%;} // flexbox alignment issue

//Menu Style
nav.horizontal.me-Menu{
  & > .menu-item{padding: 5px 10px;}
}
.main-menu{
  nav.me-Menu.horizontal{
    justify-content: flex-end;
    a{color: #333333;}
  }
}

.me-block.me-StickyPanel{
  .me-block.me-CartInfo{
    & > .me-iwrap > .me-block.me-MultiCol{
      max-width: 400px;
    }
  }
}

.me-block.me-Carousel{
  .item.slick-slide{
    text-align: left;
  }
  .slick-dots{
    text-align: right;
    bottom: 0;
  }
}

.me-block.me-Slider {
  position: relative;
  .slider-arrow {
    position: absolute;
    top: 50%;
    font-size: 80px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    &.slider-arrow-left {
      left: 10px;
    }
    &.slider-arrow-right {
      right: 10px;
    }
  }
  .item.slick-slide {
    position: relative;
    & > .me-block.me-MultiCol {
      position: absolute;
      width: 100%;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      .me-block.me-MultiCol {
        .column {
          flex-direction:row;
          .me-block.me-Heading {
            //padding: 10px 15px;
            //width: auto;
          }
        }
        &.text-center .column {
          align-items: center;
        }
        &.text-right .column {
          align-items: flex-end;
        }
      }
    }
  }
}

.news-title, .news-summary {
  padding: 5px 15px;
}
.news-menu-list{
  color: #333;
  padding: 5px 0;
  display: block;
  border-bottom: 1px dotted #333333;
  &:hover{
    color:#333;
  }
}

.me-block.me-CartAccount,
.me-block.me-CartAddress,
.me-block.me-CartDeliveryMessage,
.me-block.me-CartPromoCode,
.me-block.me-PayStripeCardName,
.me-block.me-PayStripeCardNo,
.me-block.me-PayStripeCardExpMo,
.me-block.me-PayStripeCardExpYr,
.me-block.me-PayStripeCardCVC,
.me-block.me-PayEWayCardName,
.me-block.me-PayEWayCardNo,
.me-block.me-PayEWayCardExpMo,
.me-block.me-PayEWayCardExpYr,
.me-block.me-PayEWayCardCVV{
  //::placeholder {
  //  color: white;
  //}
  input,textarea,select{
    //background-color: rgba(255,255,255,0.3);
    //border: 1px solid white;
    //color: white;
  }
  .not-email{
    margin-bottom: 10px;
    margin-top: -15px;
    font-size: 14px;
    color:black;
  }
}

.me-block.me-CartMessage{
  & > div{
    background:transparent;
    color: black;
    border-radius: 10px;
    text-align: center;
  }
}

.footer {
  nav {
    &.me-Menu.horizontal {
      justify-content: center;
      @media #{$large-up} {
        justify-content: flex-start;
      }
    }

    &.me-Menu.vertical {
      align-items: center;
      @media #{$large-up}{
        align-items: flex-start;
      }
    }
  }
}
